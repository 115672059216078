import React from "react";
import { func } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useUser, signOut } from "../../hooks/useUser";
import { useMediaQuery } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

// import "./Header.scss";

const drawerWidth = 0; // 300;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
}));

const propTypes = {
  onSignIn: func,
  onToggleMenu: func,
};

const Header = ({ onSignIn, onToggleMenu }) => {
  const desktop = useMediaQuery("(min-width:1200px)");
  const classes = useStyles();
  const { user } = useUser();
  // console.log("user: ", user);
  const handleLogOut = () => {
    signOut();
  };
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {!desktop ? (
          <>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={onToggleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Debt Timeline
            </Typography>
          </>
        ) : (
          <div style={{ flexGrow: 1 }} />
        )}
        {!user ? (
          <Button color="inherit" onClick={onSignIn}>
            Sign In
          </Button>
        ) : (
          <Button color="inherit" onClick={handleLogOut}>
            Log Out
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = propTypes;

export default Header;
