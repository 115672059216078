function commas(x) {
  const round = Math.round(x * 100) / 100;
  return round.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function sum(arr, key) {
  let sum = 0;

  if (typeof key === "function") {
    sum = arr.reduce((a, v) => a + +key(v), 0);
  } else if (typeof key === "string") {
    sum = arr.reduce((a, v) => a + +v[key], 0);
  } else {
    sum = arr.reduce((a, v) => a + +v, 0);
  }
  return Math.round(sum * 100) / 100;
}

function toKebabCase(str) {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join("-")
  );
}

export { commas, sum, toKebabCase };
