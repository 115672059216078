import {
  yellow,
  blue,
  cyan,
  orange,
  deepPurple,
} from "@material-ui/core/colors";

const COLORS = [
  yellow[500],
  blue[500],
  deepPurple[500],
  cyan[500],
  orange[500],
];

function getColor(index = 0) {
  return COLORS[index];
}

function getColors() {
  return COLORS;
}

function getPurples() {
  return [
    deepPurple[200],
    deepPurple[300],
    deepPurple[400],
    deepPurple[500],
    deepPurple[600],
    deepPurple[700],
    deepPurple[800],
  ];
}

export { getColor, getColors, getPurples };
