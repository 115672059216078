import React, { useState } from "react";
import { node, object } from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  ClickAwayListener,
  IconButton,
  Tooltip,
  // Typography,
} from "@material-ui/core";
import { Help } from "@material-ui/icons";
// import "./HelperTooltip.scss";

const HtmlTooltip = withStyles((theme) => ({
  popper: {
    pointerEvents: "auto",
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const propTypes = {
  title: node,
  style: object,
};

const HelperTooltip = ({ title, style }) => {
  const [open, setOpen] = useState(false);
  const [mouseEvent, setMouseEvent] = useState(false);
  const [touchEvent, setTouchEvent] = useState(false);

  const handleClick = () => {
    setOpen((wasOpen) => {
      if (wasOpen) {
        handleTooltipClose();
        return false;
      } else {
        setMouseEvent("onClick");
        setTouchEvent("onTouchEnd");
        return true;
      }
    });
  };

  const handleTooltipClose = () => {
    setOpen(false);
    setMouseEvent(false);
    setTouchEvent(false);
  };

  return (
    <ClickAwayListener
      mouseEvent={mouseEvent}
      touchEvent={touchEvent}
      onClickAway={handleTooltipClose}
    >
      <div>
        <HtmlTooltip
          title={title}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={handleTooltipClose}
        >
          <IconButton size="small" style={style} onClick={handleClick}>
            <Help fontSize="inherit" />
          </IconButton>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  );
};

HelperTooltip.propTypes = propTypes;

export default HelperTooltip;

/* <React.Fragment>
  <Typography color="inherit">Tooltip with HTML</Typography>
  <em>{"And here's"}</em> <b>{"some"}</b> <u>{"amazing content"}</u>.{" "}
  {"It's very engaging. Right?"}
</React.Fragment> */
