import React, { useEffect, useState } from "react";
import pt from "prop-types";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";

import { toKebabCase } from "../../utils/functions";

// import "./NewDebt.scss";

const ERROR_MESSAGE = "Enter a value";

const propTypes = {
  open: pt.bool,
  initialDebt: pt.object,
  showCancel: pt.bool,
  debtTitleRef: pt.any,
  onAddDebt: pt.func,
  onClose: pt.func,
  onDelete: pt.func,
};

const NewDebt = ({
  open,
  initialDebt,
  showCancel,
  debtTitleRef,
  onAddDebt,
  onClose,
  onDelete,
}) => {
  // CONTEXT

  const desktop = useMediaQuery("(min-width:1200px)");

  // STATE

  const [title, setTitle] = useState("");
  const [pv, setPv] = useState("");
  const [rate, setRate] = useState("");
  const [pmt, setPmt] = useState("");
  const [nper, setNper] = useState("");
  const [showErrors, setShowErrors] = useState({});

  // LIFECYCLES

  useEffect(() => {
    if (open && initialDebt) {
      setTitle(initialDebt.title || "");
      setPv(initialDebt.pv || "");
      setRate(initialDebt.rate * 100 || "");
      setPmt(initialDebt.pmt || "");
      setNper(initialDebt.nper || "");
    } else if (!open) {
      setTitle("");
      setPv("");
      setRate("");
      setPmt("");
      setNper("");
      setShowErrors({});
    }
  }, [open, initialDebt]);

  // METHODS

  const handleChange = (name, set) => (event) => {
    setShowErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
    set(event.target.value);
  };

  const handleAddDebt = () => {
    if (!title && !pv && !rate && !pmt && !nper) {
      onClose();
    }

    if (!(title && pv && rate && (pmt || nper))) {
      setShowErrors({
        title: !title && ERROR_MESSAGE,
        pv: !pv && ERROR_MESSAGE,
        rate: !rate && ERROR_MESSAGE,
        pmt: !pmt && ERROR_MESSAGE,
        nper: !nper && ERROR_MESSAGE,
      });
      return;
    }

    if (!nper && pmt < pv * (rate / 1200)) {
      setShowErrors({ pmt: "Amount too low." });
      return;
    }

    const presentValue = Math.round(pv * 100) / 100;
    onAddDebt({
      id:
        initialDebt?.id ||
        toKebabCase(title) + "-" + Math.round(Math.random() * 1000),
      title: title,
      pv: presentValue,
      rate: Math.round(rate * 100) / 10000,
      pmt: pmt ? Math.round(pmt * 100) / 100 : null,
      nper: nper ? +nper : null,
    });
  };

  const handleTexfieldKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleAddDebt(event);
    }
  };

  if (!open) return null;

  return (
    <div
      style={{
        // background: "#f0f0f0",
        border: "1px solid #bbb",
        borderRadius: 8,
        margin: "0 -10px",
        padding: "20px 10px",
      }}
    >
      <div
        style={{
          margin: "0px 0 16px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontWeight: 600 }}>Add debt info</div>
        {!!initialDebt && (
          <IconButton
            style={{ padding: 6, margin: "-9px 0" }}
            onClick={onDelete}
          >
            <Delete style={{ color: red[500] }} />
          </IconButton>
        )}
      </div>
      <form>
        <TextField
          autoFocus
          margin={desktop ? "dense" : "normal"}
          label="Debt Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={handleChange("title", setTitle)}
          InputLabelProps={{ shrink: true }}
          error={!!showErrors.title}
          helperText={showErrors.title || ""}
          inputRef={debtTitleRef}
          inputProps={{ onKeyDown: handleTexfieldKeyDown }}
        />
        <TextField
          margin={desktop ? "dense" : "normal"}
          type="number"
          label="Outstanding balance"
          variant="outlined"
          fullWidth
          value={pv}
          onChange={handleChange("pv", setPv)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          inputProps={{ min: 0, onKeyDown: handleTexfieldKeyDown }}
          error={!!showErrors.pv}
          helperText={showErrors.pv || ""}
        />
        <TextField
          margin={desktop ? "dense" : "normal"}
          type="number"
          label="Rate (per year)"
          variant="outlined"
          fullWidth
          value={rate}
          onChange={handleChange("rate", setRate)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          inputProps={{ min: 0, onKeyDown: handleTexfieldKeyDown }}
          error={!!showErrors.rate}
          helperText={showErrors.rate || ""}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 30px 1.5fr",
          }}
        >
          <TextField
            margin={desktop ? "dense" : "normal"}
            type="number"
            label="No. months"
            variant="outlined"
            fullWidth
            value={nper}
            onChange={handleChange("nper", setNper)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: 0, onKeyDown: handleTexfieldKeyDown }}
            disabled={!!pmt}
            error={!!showErrors.nper}
            helperText={showErrors.nper || ""}
          />
          <span
            style={{
              textAlign: "center",
              color: "rgba(0,0,0,0.54)",
              marginTop: 18,
            }}
          >
            or
          </span>
          <TextField
            margin={desktop ? "dense" : "normal"}
            type="number"
            label="Mo. minimum"
            variant="outlined"
            fullWidth
            value={pmt}
            onChange={handleChange("pmt", setPmt)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            inputProps={{ min: 0, onKeyDown: handleTexfieldKeyDown }}
            disabled={!!nper}
            error={!!showErrors.pmt}
            helperText={showErrors.pmt || ""}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}
        >
          {showCancel && (
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 20 }}
            onClick={handleAddDebt}
          >
            Done
          </Button>
        </div>
      </form>
    </div>
  );
};

NewDebt.propTypes = propTypes;

export default NewDebt;
