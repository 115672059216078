import * as firestore from "react-firebase-hooks/firestore";

import { db, firebase } from "../config/firebase";

// **************
// 'GET' BUILDERS
// **************

const getCollectionHook = (hook) => (queryPath, options, skip) => {
  const [data, loading, error] = hook(
    queryPath ? db.collection(queryPath) : "",
    options
  );

  return skip ? [undefined, false, undefined] : [data, loading, error];
};

const getDocumentHook = (hook) => (queryPath, options, skip) => {
  const [data, loading, error] = hook(
    queryPath ? db.doc(queryPath) : "",
    options
  );

  return skip ? [undefined, false, undefined] : [data, loading, error];
};

// ****************
// USE SUBSCRIPTION
// ****************

export const useSubscription = (queryPath, options, skip) => {
  const isCollection = queryPath.split("/").length % 2 === 1;
  return isCollection
    ? getCollectionHook(firestore.useCollectionData)(queryPath, options, skip)
    : getDocumentHook(firestore.useDocumentData)(queryPath, options, skip);
};

// *********
// USE QUERY
// *********

export const useQuery = (queryPath, options, skip) => {
  const isCollection = queryPath.split("/").length % 2 === 1;
  return isCollection
    ? getCollectionHook(firestore.useCollectionDataOnce)(
        queryPath,
        options,
        skip
      )
    : getDocumentHook(firestore.useDocumentDataOnce)(queryPath, options, skip);
};

// ***********
// CREATE DOCS
// ***********

async function createDocs(collectionPath, docsToCreate) {
  if (!collectionPath || typeof collectionPath !== "string") {
    throw new Error(
      "Must include a collection path, like 'users/abc1/debts', in the hook or as the second paramter of the returned create funtion"
    );
  }
  if (!Array.isArray(docsToCreate) || docsToCreate.length === 0) {
    throw new Error("Must include a non empty array");
  }

  // Get a new write batch
  const batch = db.batch();

  const createdRefIds = [];

  docsToCreate.forEach((docToCreate) => {
    const { _id, ...updates } = docToCreate;
    const docRef = db.collection(collectionPath).doc(_id);
    batch.set(docRef, {
      ...updates,
      id: docRef.id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    createdRefIds.push(docRef.id);
  });

  // Commit the batch
  await batch.commit();
  return createdRefIds;
}

export const useCreateDocs = (collectionPath) => {
  return [
    (docsToCreate, path) => createDocs(path || collectionPath, docsToCreate),
  ];
};

// ***********
// UPDATE DOCS
// ***********

async function updateDocs(collectionPath, docsToUpdate) {
  if (!Array.isArray(docsToUpdate) || docsToUpdate.length === 0) {
    throw new Error("Must include a non empty array");
  }

  // Get a new write batch
  const batch = db.batch();

  docsToUpdate.forEach((docToUpdate) => {
    const { _id, ...updates } = docToUpdate;

    if (!_id) {
      throw new Error("All docs to update must include the doc id as '_id'");
    }

    const docRef = db.collection(collectionPath).doc(_id);
    batch.update(docRef, {
      ...updates,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  });

  // Commit the batch
  await batch.commit();
}

// ***********
// DELETE DOCS
// ***********

async function deleteDocs(collectionPath, docsIdsToDelete) {
  if (!Array.isArray(docsIdsToDelete) || docsIdsToDelete.length === 0) {
    throw new Error("Must include a non empty array");
  }

  // Get a new write batch
  const batch = db.batch();

  docsIdsToDelete.forEach((docId) => {
    if (typeof docId !== "string") {
      throw new Error("Must be an array of docId strings");
    }

    const docRef = db.collection(collectionPath).doc(docId);
    batch.delete(docRef);
  });

  // Commit the batch
  await batch.commit();
}

// **************
// MUTATION QUERY
// **************

export const useMutation = (mutationType, collectionPath) => {
  if (
    typeof mutationType !== "string" ||
    !["create", "update", "delete"].includes(mutationType)
  ) {
    throw new Error(
      "First argument must be one of 'create', 'update', 'delete'"
    );
  }

  if (mutationType === "create") {
    return [
      (docsToCreate, path) => createDocs(path || collectionPath, docsToCreate),
    ];
  } else if (mutationType === "update") {
    return [
      (docsToUpdate, path) => updateDocs(path || collectionPath, docsToUpdate),
    ];
  } else if (mutationType === "delete") {
    return [
      (docIdsToDelete, path) =>
        deleteDocs(path || collectionPath, docIdsToDelete),
    ];
  } else {
    return [null];
  }
};
