import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";

// Initialize Firebase

const firebaseConfig = {
  apiKey: "AIzaSyB2SQ_vKGY_DZI6IrQIBYEqH9WlHn7J3cI",
  authDomain: "debts-calculator.firebaseapp.com",
  projectId: "debts-calculator",
  storageBucket: "debts-calculator.appspot.com",
  messagingSenderId: "997435025795",
  appId: "1:997435025795:web:75e2084dbbda482c53aeb6",
  measurementId: "G-HN5KT6JVSM",
};

firebase.initializeApp(firebaseConfig);

// Firestore
const db = firebase.firestore();

// Authentication
const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();

// Analytics
const analytics = firebase.analytics();

// Functions
// const functions = firebase.functions();

export { firebase, db, auth, googleProvider, analytics };
