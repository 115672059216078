function fv(rate, nper, pmt, pv, type = 0) {
  var pow = Math.pow(1 + rate, nper);
  let fv = -1 * (pv + pmt * nper);

  if (rate) {
    fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
  }

  return Math.round(fv * 100) / 100;
}

function pmt(rate, nper, pv, fv = 0, type = 0) {
  if (rate === 0) return -(pv + fv) / nper;

  const pvif = Math.pow(1 + rate, nper);
  let pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);

  if (type === 1) {
    pmt /= 1 + rate;
  }

  return pmt;
}

function nper(rate, pmt, pv, fv = 0, type = 0) {
  if (rate === 0) return -(pv + fv) / pmt;

  // Return number of periods
  const num = pmt * (1 + rate * type) - fv * rate;
  const den = pv * rate + pmt * (1 + rate * type);
  return Math.log(num / den) / Math.log(1 + rate);
}

function getPmt(debt) {
  if (!debt) return 0;
  return (
    debt.pmt ||
    Math.round(pmt(debt.rate / 12, debt.nper, -debt.pv) * 100) / 100 ||
    0
  );
}

export { fv, pmt, nper, getPmt };
