import React, { Fragment, useEffect, useState } from "react";
import { array, string, func, any } from "prop-types";
import cx from "classnames";
import { Button, IconButton, Typography } from "@material-ui/core";
import {
  ArrowUpward as UpArrow,
  ArrowDownward as DownArrow,
  RemoveCircle,
} from "@material-ui/icons/";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

import { commas, sum } from "../../utils/functions";
import { useUser } from "../../hooks/useUser";
import NewDebt from "../NewDebt/NewDebt";
import x from "../SideDrawer/SideDrawer.module.scss";
import { getPmt } from "../../utils/financial";
import HelperTooltip from "../HelperTooltip/HelperTooltip";

const propTypes = {
  debtTitleRef: any,
  debts: array,
  hidden: array,
  order: string,
  onAddDebt: func,
  onUpdateDebt: func,
  onOrder: func,
  onDeleteDebt: func,
  onHideDebt: func,
};

const DebtsList = ({
  debtTitleRef,
  debts = [],
  hidden = [],
  order,
  onAddDebt,
  onUpdateDebt,
  onOrder,
  onDeleteDebt,
  onHideDebt,
}) => {
  const editingRef = React.useRef();
  const { user } = useUser();
  const [showNew, setShowNew] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [debtToEdit, setDebtToEdit] = useState(null);
  const userLoggedIn = !!user;
  const hasDebts = debts.length > 0;

  // Set up a clickaway listener when in edit mode
  useEffect(() => {
    function handleAwayClick(event) {
      if (editingRef.current && editingRef.current.contains(event.target)) {
        return;
      }
      setShowEdit(false);
    }

    if (showEdit) {
      document.addEventListener("click", handleAwayClick);
    } else {
      document.removeEventListener("click", handleAwayClick);
    }

    return () => {
      document.removeEventListener("click", handleAwayClick);
    };
  }, [showEdit]);

  // hide new if after login and debts exist, and after logout
  useEffect(() => {
    if (hasDebts) {
      setShowNew(false);
    } else if (!userLoggedIn) {
      setShowNew(true);
    }
  }, [userLoggedIn, hasDebts]);

  // clear debtToEdit whenever window is closed
  useEffect(() => {
    if (!showNew) {
      setDebtToEdit(null);
    }
  }, [showNew]);

  const handleAddDebt = async (newDebt) => {
    if (debtToEdit) {
      onUpdateDebt(newDebt);
    } else {
      onAddDebt(newDebt);
    }
    setShowNew(false);
  };

  const handleEdit = (debt) => {
    setDebtToEdit(debt);
    setShowNew(true);
  };

  const handleDeleteDebt = () => {
    onDeleteDebt(debtToEdit.id);
    setDebtToEdit(null);
    setShowNew(false);
  };

  const handleHideDebt = (event, debtId) => {
    event.stopPropagation();
    onHideDebt(debtId, hidden.includes(debtId));
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Typography variant="h4" style={{ marginBottom: 30 }}>
          1. Enter Debts
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ToggleButtonGroup
            size="small"
            value={order}
            exclusive
            onChange={(e, v) => onOrder(v)}
            aria-label="payoff type"
          >
            <ToggleButton
              value="avalanche"
              aria-label="avalanche"
              disabled={showNew}
            >
              <UpArrow fontSize="small" />
              <span style={{ fontSize: 18, margin: "-6px 0" }}>%</span>
            </ToggleButton>
            <ToggleButton
              value="snowball"
              aria-label="snowball"
              disabled={showNew}
            >
              <DownArrow fontSize="small" />
              <span style={{ fontSize: 18, margin: "-6px 0" }}>$</span>
            </ToggleButton>
          </ToggleButtonGroup>
          <HelperTooltip
            style={{ marginLeft: 6, padding: 6 }}
            title={
              <Fragment>
                There are two ways to sort your debts:
                <ol>
                  <li>Largest % first ({`"avalanche"`})</li>
                  <li>Smallest $ first ({`"snowball"`})</li>
                </ol>
                The first option you pay the <i>least</i> interest; the second
                option gives you the <i>quickest</i> win!
              </Fragment>
            }
          />
          {/* <IconButton size="small" style={{ marginLeft: 4 }}>
              <Help fontSize="inherit" />
            </IconButton> */}
        </div>
        <div className={x.debtsTotal}>
          $
          {commas(
            sum(debts.filter((d) => !hidden.includes(d.id)).map((d) => d.pv))
          )}
        </div>
        {/* <Button
            disabled={showNew}
            onClick={() => setShowEdit((prev) => !prev)}
          >
            {showEdit ? "Done" : "Edit"}
          </Button> */}
      </div>
      <div ref={editingRef} className={x.debtRows}>
        {debts.map((debt) => (
          <div
            key={debt.id}
            className={cx([
              x.rowDebt,
              { [x.debtHidden]: hidden.includes(debt.id) },
            ])}
            onClick={() => handleEdit(debt)}
          >
            <div>
              <div className={x.debtTitle}>{debt.title}</div>
              <div className={x.debtRate}>
                {(debt.rate * 100).toFixed(1)}% • ${commas(getPmt(debt))}
              </div>
            </div>
            <div className={x.flex}>
              <div>
                <div className={x.debtTotal}>${commas(debt.pv)}</div>
                <div className={x.debtHide}>
                  <span onClick={(e) => handleHideDebt(e, debt.id)}>
                    {hidden.includes(debt.id) ? "Show" : "Hide"}
                  </span>
                </div>
              </div>
              <div
                className={x.debtDelete}
                style={{
                  maxWidth: showEdit ? 40 : 0,
                  marginLeft: showEdit ? 12 : 0,
                }}
              >
                <IconButton
                  style={{ padding: 6 }}
                  aria-label="delete debt"
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={() => onDeleteDebt(debt.id)}
                >
                  <RemoveCircle />
                </IconButton>
              </div>
            </div>
          </div>
        ))}
      </div>
      {!showNew && (
        <Button variant="outlined" fullWidth onClick={() => setShowNew(true)}>
          Add another
        </Button>
      )}
      {/* *************** */}
      {/* NEW DEBT DIALOG */}
      {/* *************** */}
      <NewDebt
        open={showNew}
        initialDebt={debtToEdit}
        showCancel={debts.length > 0}
        debtTitleRef={debtTitleRef}
        onAddDebt={handleAddDebt}
        onClose={() => setShowNew(false)}
        onDelete={handleDeleteDebt}
      />
    </>
  );
};

DebtsList.propTypes = propTypes;

export default DebtsList;
