import React, { useState } from "react";
import { bool, node, func, array, string, number } from "prop-types";
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import AccountAccessForm from "../AccountAccessForm/AccountAccessForm";
// import "./SignInDialog.module.scss";

const propTypes = {
  open: bool,
  initialType: string,
  debts: array,
  extraPayment: number,
  order: string,
  onClose: func,
};

const SignInDialog = ({ initialType, debts, extraPayment, order, onClose }) => {
  const [signUp, setSignUp] = useState(initialType === "signUp");

  const handleToggleType = () => {
    setSignUp((prev) => !prev);
  };

  return (
    <>
      <DialogTitle>
        {signUp ? "Create an account" : "Sign In to your Account"}
        {onClose ? (
          <IconButton
            style={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            aria-label="close"
            onClick={onClose}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent style={{ maxWidth: 340, width: "100%" }}>
        <AccountAccessForm
          isSignIn={!signUp}
          buttonVariant="contained"
          debts={debts}
          extraPayment={extraPayment}
          order={order}
          onSuccess={onClose}
        />
        <div style={{ fontSize: 14, margin: "4px 0 16px" }}>
          {signUp ? `Already have an account? ` : `Don't have an account yet? `}
          <Link style={{ cursor: "pointer" }} onClick={handleToggleType}>
            {signUp ? "Sign In" : "Create Account"}
          </Link>
        </div>
      </DialogContent>
    </>
  );
};

SignInDialog.propTypes = propTypes;

const SignInDialogWrapper = (props) => (
  <Dialog open={props.open} onClose={props.onClose} maxWidth="xs">
    <SignInDialog {...props} />
  </Dialog>
);

SignInDialogWrapper.propTypes = propTypes;

export default SignInDialogWrapper;

const Dialog = (props) => {
  const [renderChildren, setRenderChildren] = useState(false);
  return (
    <MuiDialog
      onEnter={() => setRenderChildren(true)}
      onExited={() => setRenderChildren(false)}
      {...props}
    >
      {renderChildren ? props.children : false}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  children: node,
};
