import React, { useEffect, useState } from "react";
import { bool, array, number, string, func } from "prop-types";
import { Visibility, VisibilityOff } from "@material-ui/icons/";
import { Alert } from "@material-ui/lab";
import zxcvbn from "zxcvbn";

import { getErrorMessage } from "../../utils/errorMessages";
import { useCreateUser } from "../../hooks/useCreateUser";
import { auth } from "../../config/firebase";
import { green, orange, red } from "@material-ui/core/colors";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
// import "./AccountAccessForm.scss";

const EMPTY_ERROR = "Cannot be blank.";
const INVALID_EMAIL_ERROR = "Not a valid email.";
const PASSWORD_TOO_WEAK_ERROR = "Your password is too weak.";

const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const propTypes = {
  isSignIn: bool,
  buttonVariant: string,
  debts: array,
  extraPayment: number,
  order: string,
  onSuccess: func,
};

const AccountAccessForm = ({
  isSignIn = false,
  buttonVariant,
  debts,
  extraPayment,
  order,
  onSuccess,
}) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordFeedback, setPasswordFeedback] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");

  // LIFECYCLES

  // reset server error when switching to and from signIn
  useEffect(() => {
    setServerErrorMessage("");
  }, [isSignIn]);

  // MUTATIONS

  const createUser = useCreateUser();

  // METHODS

  const validation = (type) => {
    // email
    let emailErrorMessage = "";

    if (!email) {
      emailErrorMessage = EMPTY_ERROR;
    } else if (!email.match(mailformat)) {
      emailErrorMessage = INVALID_EMAIL_ERROR;
    }

    setEmailError(emailErrorMessage);

    // password
    let passwordErrorMessage = "";

    if (!password) {
      passwordErrorMessage = EMPTY_ERROR;
    } else if (type === "create" && passwordScore < 3) {
      passwordErrorMessage =
        passwordFeedback.warning ||
        passwordFeedback?.suggestions?.[0] ||
        PASSWORD_TOO_WEAK_ERROR;
    }

    setPasswordError(passwordErrorMessage);
    // return true if both valid
    return !(emailErrorMessage || passwordErrorMessage);
  };

  const handleCreateAccount = async () => {
    setServerErrorMessage("");

    if (validation("create")) {
      try {
        await createUser(email, password, debts, extraPayment, order);
        setEmail("");
        setPassword("");

        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        setServerErrorMessage(getErrorMessage(error.code));
      }
    }
  };

  const handleSignIn = async () => {
    setServerErrorMessage("");

    if (validation("signIn")) {
      try {
        await auth.signInWithEmailAndPassword(email, password);
        setEmail("");
        setPassword("");

        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        setServerErrorMessage(getErrorMessage(error.code));
      }
    }
  };

  const handleEmail = (event) => {
    setEmailError("");
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPasswordError("");
    const value = event.target.value;
    setPassword(value);

    if (!isSignIn) {
      const zxcResult = zxcvbn(value);
      setPasswordScore(zxcResult.score);
      setPasswordFeedback(zxcResult.feedback);
    }
  };

  const handleTexfieldKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (isSignIn) {
        handleSignIn();
      } else {
        handleCreateAccount();
      }
    }
  };

  const getColor = (value) => {
    let filledColor =
      passwordScore > 2
        ? green[500]
        : passwordScore > 1
        ? orange[500]
        : red[500];

    return value <= passwordScore ? filledColor : "#eee";
  };

  return (
    <form>
      {serverErrorMessage && (
        <div style={{ marginBottom: 20 }}>
          <Alert severity="error">{serverErrorMessage}</Alert>
        </div>
      )}
      <TextField
        style={{ margin: "8px 0" }}
        autoFocus={isSignIn}
        autoComplete="email"
        label="Email"
        variant="outlined"
        size="small"
        fullWidth
        value={email}
        onChange={handleEmail}
        error={!!emailError}
        helperText={emailError}
        inputProps={{ onKeyDown: handleTexfieldKeyDown }}
      />
      <TextField
        style={{ margin: "8px 0 8px" }}
        type={showPassword ? "text" : "password"}
        autoComplete={isSignIn ? "current-password" : "new-password"}
        label="Password"
        variant="outlined"
        size="small"
        fullWidth
        value={password}
        onChange={handlePassword}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{ padding: 6 }}
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prev) => !prev)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              {!isSignIn && (
                <div
                  style={{
                    margin: "0 -6px 0 16px",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateRows: "repeat(4, 5px)",
                      gridRowGap: 3,
                    }}
                  >
                    {[4, 3, 2, 1].map((value) => (
                      <div
                        key={value}
                        style={{
                          width: 4,
                          height: "100%",
                          background: getColor(value),
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </InputAdornment>
          ),
        }}
        error={!!passwordError}
        helperText={passwordError}
        inputProps={{ onKeyDown: handleTexfieldKeyDown }}
      />
      <Button
        style={{ margin: "8px 0 16px" }}
        variant={buttonVariant || "outlined"}
        color="primary"
        fullWidth
        onClick={isSignIn ? handleSignIn : handleCreateAccount}
      >
        {isSignIn ? "Sign In" : "Create Account"}
      </Button>
    </form>
  );
};

AccountAccessForm.propTypes = propTypes;

export default AccountAccessForm;
