import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TabContext, TabPanel } from "@material-ui/lab";

import Header from "../Header/Header";
import SideDrawer from "../SideDrawer/SideDrawer";
import Chart from "../Chart/Chart";
import PaymentSlider from "../PaymentSlider/PaymentSlider";
import MonthlyBreakdown from "../MonthlyBreakdown/MonthlyBreakdown";
import InterestChart from "../InterestChart/InterestChart";
import { usePayments } from "../../hooks/usePayments";
import LoansSummary from "../LoansSummary/LoansSummary";
import SignInDialog from "../SignInDialog/SignInDialog";
import { useUser } from "../../hooks/useUser";
import { useSubscription, useMutation } from "../../hooks/useFirestore";
import { firebase } from "../../config/firebase";
import { getPmt } from "../../utils/financial";
import x from "./App.module.scss";
import DebtsList from "../DebtsList/DebtsList";
import { ExpandMore } from "@material-ui/icons";
import BottomDrawer from "../BottomDrawer/BottomDrawer";

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

function getMinPayment(debts) {
  return Math.round(debts.reduce((a, v) => a + getPmt(v), 0) * 100) / 100;
}

function App() {
  // REFS

  const debtTitleRef = React.useRef();

  // CONTEXT

  const { user } = useUser();
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:1200px)");
  // const desktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  // DATA STATE

  // const [localDebts, setLocalDebts] = useState(
  //   getDefaultDebts().sort((a, b) => b.rate - a.rate)
  // );
  const [localOrder, setLocalOrder] = useState("avalanche"); // avalanche | snowball
  const [localHidden, setLocalHidden] = useState([]);
  const [localDebts, setLocalDebts] = useState([]);
  const [localExtra, setLocalExtra] = useState(0);

  // UI STATE

  const [tab, setTab] = useState("summary"); // summary | details
  const [showSignIn, setShowSignIn] = useState(false);
  const [mobileDetailsExpanded, setMobileDetailsExpanded] = React.useState("");
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const [signInType, setSignInType] = useState(false);

  useEffect(() => {
    if (!showSignIn) {
      setSignInType(false);
    }
  }, [showSignIn]);

  const userLoggedIn = !!user;

  useEffect(() => {
    if (!userLoggedIn) {
      setLocalOrder("avalanche");
      setLocalHidden([]);
      setLocalDebts([]);
      setLocalExtra(0);
    }
  }, [userLoggedIn]);

  // QUERIES

  const [debtsData] = useSubscription(
    user ? `users/${user.id}/debts` : "",
    undefined,
    !user
  );
  const [scenarioData] = useSubscription(
    user ? `users/${user.id}/scenarios/${user.activeScenario}` : "",
    undefined,
    !user
  );

  // MUTATIONS

  const [updateUserScenarios] = useMutation(
    "update",
    `users/${user?.id || ""}/scenarios`
  );
  const [createUserDebts] = useMutation(
    "create",
    `users/${user?.id || ""}/debts`
  );
  const [updateUserDebts] = useMutation(
    "update",
    `users/${user?.id || ""}/debts`
  );
  const [deleteUserDebts] = useMutation(
    "delete",
    `users/${user?.id || ""}/debts`
  );

  // COMPUTED VALUES
  const order = scenarioData?.order || localOrder;
  const hidden = scenarioData?.hidden || localHidden;
  const debts = debtsData || localDebts;
  const sortedDebts = (function () {
    if (order === "avalanche") {
      return debts.sort((a, b) => b.rate - a.rate);
    } else if (order === "snowball") {
      return debts.sort((a, b) => a.pv - b.pv);
    } else {
      return debts;
    }
  })();
  const filteredDebts = sortedDebts.filter((debt) => !hidden.includes(debt.id));
  const extraPayment = scenarioData?.extraPayment || localExtra || 0;
  const minPayment = getMinPayment(filteredDebts);
  const payment = minPayment + extraPayment;
  const { periods, totalInterest } = usePayments(
    filteredDebts,
    payment,
    "plan"
  );
  const {
    periods: minimumPeriods,
    principal,
    totalInterest: totalMinimumInterest,
  } = usePayments(filteredDebts, minPayment, "min");
  const monthDiff = minimumPeriods.length - periods.length;

  // METHODS

  const handleAddDebt = async (debt) => {
    if (user?.activeScenario) {
      const debtIds = await createUserDebts([debt]);
      updateUserScenarios([
        {
          _id: user.activeScenario,
          debts: firebase.firestore.FieldValue.arrayUnion(...debtIds),
        },
      ]);
    }

    setLocalDebts((prev) => {
      const newDebts = [...prev, debt];
      return newDebts;
    });

    if (!desktop) {
      window.scrollTo(0, 0);
    }
  };

  const handleUpdateDebt = async (debt) => {
    if (user?.activeScenario) {
      const { id, ...updates } = debt;
      const updatedDebt = {
        _id: id,
        ...updates,
      };
      await updateUserDebts([updatedDebt]);
    }

    setLocalDebts((prev) => {
      const newDebts = [...prev.filter((d) => d.id !== debt.id), debt];
      return newDebts;
    });
  };

  const handleDeleteDebt = (debtId) => {
    if (user?.activeScenario) {
      deleteUserDebts([debtId]);
      updateUserScenarios([
        {
          _id: user.activeScenario,
          debts: firebase.firestore.FieldValue.arrayRemove(debtId),
          hidden: firebase.firestore.FieldValue.arrayRemove(debtId),
        },
      ]);
    }

    setLocalDebts((prev) => {
      const newDebts = prev.filter((d) => d.id !== debtId);
      return newDebts;
    });
  };

  const handleHideDebt = (debtId, show = false) => {
    if (user?.activeScenario) {
      updateUserScenarios([
        {
          _id: user.activeScenario,
          hidden: show
            ? firebase.firestore.FieldValue.arrayRemove(debtId)
            : firebase.firestore.FieldValue.arrayUnion(debtId),
        },
      ]);
    }

    setLocalHidden((prev) => {
      const newDebts = [
        ...prev.filter((d) => d !== debtId),
        ...(show ? [] : [debtId]),
      ];
      return newDebts;
    });
  };

  const handlePaymentSlider = (extra) => {
    if (user?.activeScenario) {
      updateUserScenarios([{ _id: user.activeScenario, extraPayment: extra }]);
    }

    setLocalExtra(extra);
  };

  const handleOrder = (order) => {
    if (user?.activeScenario) {
      updateUserScenarios([{ _id: user.activeScenario, order }]);
    }

    setLocalOrder(order);
  };

  const handleChangeTab = (e, value) => {
    setTab(value);
  };

  const handleGetStarted = () => {
    debtTitleRef.current.focus();
  };

  const handleExpandDetailsChange = (panel) => (event, isExpanded) => {
    setMobileDetailsExpanded(isExpanded ? panel : "");
  };

  const handleCreateAccountRequest = () => {
    setSignInType("signUp");
    setShowSignIn(true);
  };

  // DOM

  return (
    <div className={x.container} style={{ display: "flex" }}>
      <Header
        onSignIn={() => setShowSignIn(true)}
        onToggleMenu={() => setMobileDrawerOpen(true)}
      />
      <SignInDialog
        open={showSignIn}
        initialType={signInType}
        debts={debts}
        extraPayment={extraPayment}
        order={order}
        onClose={() => setShowSignIn(false)}
      />
      {desktop ? (
        <SideDrawer
          debts={sortedDebts}
          extraPayment={extraPayment}
          order={order}
        >
          <DebtsList
            debtTitleRef={debtTitleRef}
            debts={debts}
            hidden={hidden}
            order={order}
            onAddDebt={handleAddDebt}
            onUpdateDebt={handleUpdateDebt}
            onOrder={handleOrder}
            onDeleteDebt={handleDeleteDebt}
            onHideDebt={handleHideDebt}
          />
        </SideDrawer>
      ) : (
        <>
          <SideDrawer
            open={mobileDrawerOpen}
            debts={sortedDebts}
            extraPayment={extraPayment}
            order={order}
            swipable
            onClose={() => setMobileDrawerOpen(false)}
          />
          <BottomDrawer
            open={!user}
            onCreateAccountRequest={handleCreateAccountRequest}
          />
        </>
      )}
      <Container className={x.content} maxWidth={false}>
        <div className={classes.toolbar} />
        <div
          style={
            desktop
              ? {
                  display: "grid",
                  gridTemplateColumns: "auto 425px",
                  gridColumnGap: 40,
                }
              : {
                  display: "grid",
                  gridTemplateRows: "auto auto auto",
                  gridRowGap: 20,
                }
          }
        >
          {!desktop && (
            <div style={{ marginBottom: 20, maxWidth: 400 }}>
              <DebtsList
                debtTitleRef={debtTitleRef}
                debts={debts}
                hidden={hidden}
                order={order}
                onAddDebt={handleAddDebt}
                onUpdateDebt={handleUpdateDebt}
                onOrder={handleOrder}
                onDeleteDebt={handleDeleteDebt}
                onHideDebt={handleHideDebt}
              />
            </div>
          )}
          {!desktop && <Divider />}
          <div style={{ minWidth: 0 }}>
            <Typography variant="h4" style={{ marginBottom: 30 }}>
              2. View your Debt Timeline
            </Typography>
            {/* <Typography variant="subtitle2" style={{ marginBottom: 20 }}>
              This chart shows the remaining balance of each debt (stacked on
              top of eachother) across every month until they are all paid off.
            </Typography> */}
            {/* <div className={x.title}>2. View your Debt Timeline</div> */}
            <div style={{ height: 300, position: "relative" }}>
              {filteredDebts.length === 0 && (
                <div className={x.getStarted}>
                  <Button variant="outlined" onClick={handleGetStarted}>
                    Enter a debt to get started
                  </Button>
                </div>
              )}
              <Chart
                debts={filteredDebts}
                periods={periods}
                minimumPeriods={minimumPeriods}
              />
            </div>
            <div style={{ margin: 10 }}>
              <Typography variant="caption">
                The remaining balance of each debt for every month until each of
                them are paid off.
              </Typography>
            </div>
          </div>
          {!desktop && <Divider />}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h4" style={{ marginBottom: 30 }}>
              3. Extra Monthly Payment
            </Typography>
            {/* <Typography variant="subtitle2" style={{ marginBottom: 20 }}>
              Use the slider to see how much interest you can save by paying a
              little extra each month.
            </Typography> */}
            <PaymentSlider
              minPayment={minPayment}
              extraPayment={extraPayment}
              activeScenario={user?.activeScenario}
              onChangeCommittedValue={handlePaymentSlider}
            />
            {/* <Typography variant="caption" style={{ margin: 20 }}>
              Use the slider to see how much interest you can save by paying a
              little extra each month.
            </Typography> */}
          </div>
        </div>
        <div style={{ width: "100%", height: 40 }} />
        {desktop ? (
          <TabContext value={tab}>
            <Grid container spacing={3}>
              <Grid item lg={12}>
                <Tabs
                  value={tab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChangeTab}
                  aria-label="disabled tabs example"
                >
                  <Tab label="Summary" value="summary" />
                  <Tab label="Payment Schedule" value="details" />
                </Tabs>
              </Grid>
            </Grid>
            <Divider />
            <TabPanel value="summary">
              <div
                style={
                  desktop
                    ? {
                        display: "grid",
                        gridTemplateColumns: "1fr 1.3fr",
                        gridColumnGap: 40,
                      }
                    : {
                        display: "grid",
                        gridTemplateRows: "auto auto",
                        gridRowGap: 40,
                      }
                }
              >
                <div style={{ minWidth: 0 }}>
                  <Typography variant="h4" style={{ marginBottom: 40 }}>
                    Total Interest Paid
                  </Typography>
                  <InterestChart
                    extra={extraPayment}
                    monthDiff={monthDiff}
                    principal={principal}
                    interest={totalInterest}
                    minPaymentInterest={totalMinimumInterest}
                  />
                </div>
                <div style={{ minWidth: 0 }}>
                  <Typography variant="h4" style={{ marginBottom: 40 }}>
                    Debts Breakdown
                  </Typography>
                  <LoansSummary
                    debts={filteredDebts}
                    periods={periods}
                    principal={principal}
                    interest={totalInterest}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="details">
              <Grid container spacing={3}>
                <Grid item lg={12}>
                  <MonthlyBreakdown
                    debts={filteredDebts}
                    periods={periods}
                    minimumPeriods={minimumPeriods}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        ) : (
          <div
            style={{
              display: "grid",
              gridTemplateRows: "auto auto",
              gridRowGap: 20,
            }}
          >
            <Divider />
            <div style={{ minWidth: 0, marginBottom: 20 }}>
              <Typography variant="h4" style={{ marginBottom: 40 }}>
                Total Interest Paid
              </Typography>
              <InterestChart
                extra={extraPayment}
                monthDiff={monthDiff}
                principal={principal}
                interest={totalInterest}
                minPaymentInterest={totalMinimumInterest}
              />
            </div>
            <Divider />
            <div style={{ minWidth: 0 }}>
              <Typography variant="h4" style={{ marginBottom: 40 }}>
                Debts Breakdown
              </Typography>
              <LoansSummary
                debts={filteredDebts}
                periods={periods}
                principal={principal}
                interest={totalInterest}
              />
            </div>
            <Accordion
              style={{ overflow: "hidden", margin: "20px 0" }}
              expanded={mobileDetailsExpanded === "payment"}
              onChange={handleExpandDetailsChange("payment")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h4">Monthly Payment Schedule</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MonthlyBreakdown
                  debts={filteredDebts}
                  periods={periods}
                  minimumPeriods={minimumPeriods}
                  paymentsOnly
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{ overflow: "hidden" }}
              expanded={mobileDetailsExpanded === "balance"}
              onChange={handleExpandDetailsChange("balance")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h4">Loan Balances Schedule</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MonthlyBreakdown
                  debts={filteredDebts}
                  periods={periods}
                  minimumPeriods={minimumPeriods}
                  balancesOnly
                />
              </AccordionDetails>
            </Accordion>
          </div>
        )}
        {!desktop && !user && <div className={classes.toolbar} />}
      </Container>
    </div>
  );
}

export default App;

// function getDefaultDebts() {
//   return [
//     {
//       id: "car-loan-4231",
//       title: "Car Loan",
//       pv: 20000,
//       rate: 0.03,
//       pmt: 359.37,
//       nper: 60,
//     },
//     {
//       id: "student-loan-4231",
//       title: "Student Loan",
//       pv: 30000,
//       rate: 0.06,
//       pmt: 333.06,
//       nper: 120,
//     },
//     {
//       id: "credit-lard-4231",
//       title: "Credit Card",
//       pv: 10000,
//       rate: 0.2,
//       pmt: 222.06,
//       nper: 84,
//     },
//   ];
// }
