import React from "react";
import { array, number } from "prop-types";
import { deepPurple, grey } from "@material-ui/core/colors";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { commas } from "../../utils/functions";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import { getPmt } from "../../utils/financial";

// import "./LoansSummary.scss";

const ANIMATION_DURATION = 500;

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius = 0,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}{" "}
      </text>
      <text
        x={x}
        y={y - 20}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {index === 0 ? "Principal" : "Interest"}
      </text>
    </>
  );
};

const propTypes = {
  debts: array,
  periods: array,
  principal: number,
  interest: number,
};

const LoansSummary = ({ debts = [], periods, principal, interest }) => {
  // CONTEXT

  const desktop = useMediaQuery("(min-width:1200px)");

  // COMPUTED

  const dataPie = [
    { name: "planned", value: principal || 1 },
    { name: "interest", value: interest || 0 },
  ];

  const showTotal = debts.length !== 1;

  return (
    <div
      style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
    >
      <div
        style={{
          height: 200,
          width: desktop ? 200 : 300,
          // margin: `30px ${desktop ? 0 : "auto"}`,
          ...(desktop ? { marginRight: 30 } : { margin: "0 0 30px" }),
          minWidth: 0,
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={dataPie}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={principal === 0 ? () => {} : renderCustomizedLabel}
              // label
              outerRadius={90}
              dataKey="value"
              startAngle={90}
              endAngle={90 + 360}
              animationBegin={0}
              animationDuration={ANIMATION_DURATION}
            >
              {dataPie.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    principal === 0
                      ? grey[300]
                      : deepPurple[index === 0 ? 400 : 300]
                  }
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      {desktop ? (
        <div style={{ minWidth: 0 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                {showTotal && <TableCell align="center">Total</TableCell>}
                {debts.map((debt) => (
                  <TableCell key={debt.title} align="center">
                    {debt.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* PRINCIPAL */}
              <TableRow>
                <TableCell component="th" scope="row">
                  Principal
                </TableCell>
                {showTotal && (
                  <TableCell component="th" scope="row">
                    {commas(principal)}
                  </TableCell>
                )}
                {debts.map((debt, i) => (
                  <TableCell key={debt.id} align="center">
                    {commas(debt.pv)}
                  </TableCell>
                ))}
              </TableRow>
              {/* INTEREST */}
              <TableRow>
                <TableCell component="th" scope="row">
                  Interest
                </TableCell>
                {showTotal && (
                  <TableCell component="th" scope="row">
                    {commas(interest)}
                  </TableCell>
                )}
                {debts.map((debt, i) => (
                  <TableCell key={debt.id} align="center">
                    {getInterest(periods, debt, i)}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell colSpan={2 + debts.length} />
              </TableRow>
              {/* PERCENT */}
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ whiteSpace: "nowrap" }}
                >
                  % Interest
                </TableCell>
                {showTotal && (
                  <TableCell component="th" scope="row">
                    {Math.round(
                      (interest / (principal + interest) || 0) * 10000
                    ) / 100}
                    %
                  </TableCell>
                )}
                {/* PAYMENTS */}
                {debts.map((debt, i) => (
                  <TableCell key={debt.id} align="center">
                    {getPercent(periods, debt.pv, i)}%
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      ) : (
        <div style={{ minWidth: 0, maxWidth: 500, flexGrow: 1 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ padding: "6px 8px" }} />
                <TableCell style={{ padding: "6px 8px" }} align="center">
                  Principal
                </TableCell>
                <TableCell style={{ padding: "6px 8px" }} align="center">
                  Interest
                </TableCell>
                <TableCell
                  style={{ padding: "6px 8px", whiteSpace: "nowrap" }}
                  align="center"
                >
                  % Int.
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {debts.map((debt, i) => (
                <TableRow key={debt.id}>
                  {/* TITLE */}
                  <TableCell
                    style={{ padding: "6px 8px" }}
                    align="left"
                    component="th"
                  >
                    {debt.title}
                  </TableCell>
                  {/* PRINCIPAL */}
                  <TableCell style={{ padding: "6px 8px" }} align="center">
                    {commas(debt.pv)}
                  </TableCell>
                  {/* INTEREST */}
                  <TableCell style={{ padding: "6px 8px" }} align="center">
                    {getInterest(periods, debt, i)}
                  </TableCell>
                  {/* PERCENT */}
                  <TableCell style={{ padding: "6px 8px" }} align="center">
                    {getPercent(periods, debt.pv, i)}%
                  </TableCell>
                </TableRow>
              ))}
              {showTotal && (
                <TableRow>
                  <TableCell
                    style={{ padding: "6px 8px" }}
                    variant="head"
                    align="left"
                  >
                    Total
                  </TableCell>
                  <TableCell
                    style={{ padding: "6px 8px" }}
                    variant="head"
                    scope="row"
                    align="center"
                  >
                    {commas(principal)}
                  </TableCell>
                  <TableCell
                    style={{ padding: "6px 8px" }}
                    variant="head"
                    scope="row"
                    align="center"
                  >
                    {commas(interest)}
                  </TableCell>
                  <TableCell
                    style={{ padding: "6px 8px" }}
                    variant="head"
                    scope="row"
                    align="center"
                  >
                    {Math.round(
                      (interest / (principal + interest) || 0) * 10000
                    ) / 100}
                    %
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

LoansSummary.propTypes = propTypes;

export default LoansSummary;

function getTotalDebtPayments(periods, index) {
  return periods.reduce((r, periodDebts) => r + getPmt(periodDebts[index]), 0);
}

function getInterest(periods, debt, index) {
  return commas(getTotalDebtPayments(periods, index) - debt.pv);
}

function getPercent(periods, principal, index) {
  const pmts = getTotalDebtPayments(periods, index);
  const interest = pmts - principal;
  return Math.round((interest / pmts) * 10000) / 100;
}
