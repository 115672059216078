function getErrorMessage(code) {
  switch (code) {
    // createUserWithEmailAndPassword
    case "auth/email-already-in-use":
      return "An account with this email has already been created.";
    case "auth/invalid-email":
      return "You have entered an invalid email.";
    case "auth/weak-password":
      return "Your password is too weak.";
    // signInWithEmailAndPassword
    case "auth/auth/user-not-found":
      return "No account found for this email.";
    case "auth/wrong-password":
      return "Incorrect password entered for this email.";
    default:
      return "";
  }
}

export { getErrorMessage };
