import React, { useState } from "react";
import { array, bool } from "prop-types";
import { DateTime } from "luxon";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import { commas, sum } from "../../utils/functions";
import { getPmt } from "../../utils/financial";
import x from "./MonthlyBreakdown.module.scss";

const propTypes = {
  debts: array,
  periods: array,
  minimumPeriods: array,
  balancesOnly: bool,
  paymentsOnly: bool,
};
function MonthlyBreakdown({
  debts = [],
  periods,
  minimumPeriods,
  balancesOnly,
  paymentsOnly,
}) {
  const [type, setType] = useState("planned"); // planned | minimum

  const getExtra = (planned, minimum) => {
    // console.log("planned, minimum: ", planned, minimum);
    const extra = Math.round((planned - minimum) * 100) / 100;
    if (extra > 0) {
      return <span className={x.extra}>(+${commas(extra)})</span>;
    } else {
      return null;
    }
  };
  const getPeriodFormat = (value) => {
    const now = DateTime.now();
    const period = now.plus({ months: value });
    return period.toFormat("MMM yyyy");
  };
  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
        {!paymentsOnly && !balancesOnly && (
          <div>
            <Typography variant="h4">Monthly Payment Schedule</Typography>
            <Typography variant="subtitle2" style={{ marginBottom: 50 }}>
              See your monthly payments and debt balances for every month.
            </Typography>
          </div>
        )}
        {/* <div className={x.title}>Payment Schedule</div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 30,
          }}
        >
          <div>
            <ToggleButtonGroup
              size="small"
              value={type}
              exclusive
              onChange={(e, v) => setType(v)}
              aria-label="payment type"
            >
              <ToggleButton value="planned" aria-label="planned">
                Planned
              </ToggleButton>
              <ToggleButton value="minimum" aria-label="minimum">
                Minimum
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div />
      </div>
      <TableContainer>
        <Table size="small" style={{ width: "auto", minWidth: "70%" }}>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2} />
              <TableCell align="center" rowSpan={2}>
                Month
              </TableCell>
              {!balancesOnly && (
                <TableCell
                  colSpan={debts.length + (debts.length !== 1 ? 1 : 0)}
                  align="center"
                  style={{ backgroundColor: "#f4f4f4" }}
                >
                  Monthly Payments
                </TableCell>
              )}
              {!paymentsOnly && (
                <TableCell
                  colSpan={debts.length + (debts.length !== 1 ? 1 : 0)}
                  align="center"
                >
                  Loan Balances
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              {/* <TableCell colSpan={2} /> */}
              {/* PAYMENTS */}
              {!balancesOnly && (
                <>
                  {debts.map((debt) => (
                    <TableCell
                      key={debt.title}
                      align="center"
                      style={{ backgroundColor: "#f4f4f4" }}
                    >
                      {debt.title}
                    </TableCell>
                  ))}
                  {debts.length !== 1 && (
                    <TableCell
                      align="center"
                      style={{ backgroundColor: "#f4f4f4" }}
                    >
                      Per Month
                    </TableCell>
                  )}
                </>
              )}
              {!paymentsOnly && (
                <>
                  {/* ENDING BALANCE */}
                  {debts.map((debt) => (
                    <TableCell key={debt.title} align="center">
                      {debt.title}
                    </TableCell>
                  ))}
                  {debts.length !== 1 && (
                    <TableCell align="center">Total</TableCell>
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              debts.map((d) => ({ ...d, fv: d.pv })),
              ...(type === "minimum" ? minimumPeriods : periods),
            ].map((periodDebts, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {index}
                </TableCell>
                <TableCell align="center">{getPeriodFormat(index)}</TableCell>
                {/* PAYMENTS */}
                {!balancesOnly && (
                  <>
                    {periodDebts.map((periodDebt, i) => (
                      <TableCell
                        key={periodDebt.id}
                        align="center"
                        style={{ backgroundColor: "#f4f4f4" }}
                      >
                        {index !== 0 && (
                          <>
                            ${commas(getPmt(periodDebt))}
                            {type === "planned" &&
                              getExtra(
                                getPmt(periodDebt),
                                getPmt(minimumPeriods[index - 1]?.[i])
                              )}
                          </>
                        )}
                      </TableCell>
                    ))}
                    {debts.length !== 1 && (
                      <TableCell
                        align="center"
                        style={{ backgroundColor: "#f4f4f4" }}
                      >
                        {index !== 0 && `$${commas(sum(periodDebts, getPmt))}`}
                      </TableCell>
                    )}
                  </>
                )}
                {/* ENDING BALANCE */}
                {!paymentsOnly && (
                  <>
                    {periodDebts.map((periodDebt, i) => (
                      <TableCell key={periodDebt.id} align="center">
                        ${commas(periodDebt.fv)}
                      </TableCell>
                    ))}
                    {debts.length !== 1 && (
                      <TableCell align="center">
                        ${commas(sum(periodDebts, "fv"))}
                      </TableCell>
                    )}
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

MonthlyBreakdown.propTypes = propTypes;
export default MonthlyBreakdown;
