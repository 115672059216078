import React, { useEffect, useState } from "react";
import { func, number, string } from "prop-types";
import debounce from "lodash.debounce";
import {
  InputAdornment,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { green, grey } from "@material-ui/core/colors";

import x from "./PaymentSlider.module.scss";
import { commas } from "../../utils/functions";

const ThermoSlider = withStyles({
  vertical: {
    "&.MuiSlider-root": {
      height: "auto",
      margin: "15px 60px 90px 50px",
      padding: "0 15px",
      width: 30,
      color: green[500],
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: "calc(100% + 30px)",
      left: 0,
      display: "block",
      // margin: "0 35px 10px",
      // padding: "5px",
      width: "60px",
      height: "60px",
      borderRadius: "100px",
      background: "#e5e5e5",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      top: "calc(100% + 30px)",
      left: 0,
      display: "block",
      // margin: "0 35px 10px",
      margin: "5px",
      width: "50px",
      height: "50px",
      borderRadius: "100px",
      // background: "#c5c5c5",
      background: `linear-gradient(180deg, ${green[500]} 0%, ${grey[300]} 100%)`,
    },
    "& .MuiSlider-rail": {
      width: 30,
      padding: 0,
      margin: "-15px 0 0",
      height: "calc(100% + 50px)",
      opacity: 1,
      background: "#e5e5e5",
      borderRadius: "100px 100px 0 0",
      "&:before": {
        content: '""',
        position: "absolute",
        bottom: "-10px",
        left: 0,
        display: "block",
        margin: "5px",
        width: "20px",
        height: "50px",
        borderRadius: "100px 100px 0 0",
        background: green[500],
      },
    },
    "& .MuiSlider-track": {
      margin: "0 5px -10px",
      borderRadius: "100px 100px 0 0",
      width: 20,
      boxSizing: "content-box",
      paddingBottom: 20,
    },
    "& .MuiSlider-valueLabel": {
      left: "calc(100% + 25px)",
      top: -8,
      "& *": {
        background: "transparent",
        color: "#000",
      },
      fontSize: 20,
      fontWeight: 600,
      textAlign: "left",
      transform: "none !important",
    },
    "& .MuiSlider-thumb": {
      height: 16,
      width: 16,
      backgroundColor: "#fff",
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
      margin: "0 7px -8px",
      zIndex: 2,
      "&:focus, &:hover, &$active": {
        boxShadow:
          "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          boxShadow:
            "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
        },
      },
    },
    "& .MuiSlider-mark": {
      backgroundColor: "#bfbfbf",
      width: 8,
      height: 1,
      marginTop: -3,
      left: 3,
    },
    "& .MuiSlider-markLabel": {
      right: "100%",
      left: "auto",
    },
    "& .MuiSlider-markLabelActive": {
      color: "rgba(0, 0, 0, 0.54)",
    },
    // "& .MuiSlider-markActive": {
    //   opacity: 1,
    //   backgroundColor: "currentColor",
    // },
  },
  active: {},
})(Slider);

const propTypes = {
  extraPayment: number,
  minPayment: number,
  activeScenario: string,
  onChangeCommittedValue: func,
};

const PaymentSlider = ({
  extraPayment,
  minPayment,
  activeScenario,
  onChangeCommittedValue,
}) => {
  // UI STATE

  const [max, setMax] = useState(1000);
  const [extraInput, setExtraInput] = useState(0);
  const [extraTextField, setExtraTextField] = useState(0);
  const [totalPayment, setTotalPayment] = useState(minPayment || 0);

  // REFS

  // eslint-disable-next-line
  const debounceExtra = React.useCallback(
    debounce(onChangeCommittedValue, 1000),
    [activeScenario]
  );

  // eslint-disable-next-line
  const debounceTotal = React.useCallback(
    debounce(
      (value) =>
        onChangeCommittedValue(Math.max(value, minPayment) - minPayment),
      1000
    ),
    [activeScenario, minPayment]
  );

  // LIFECYCLES

  useEffect(() => {
    if (extraPayment > max * 0.9) {
      setMax((prev) => prev + 1000);
    } else if (max > 1000 && extraPayment < max * 0.2) {
      setMax((prev) => prev - 1000);
    }
  }, [extraPayment, max]);

  useEffect(() => {
    setExtraInput(extraPayment);
    setExtraTextField(extraPayment);
    setTotalPayment(Math.round((+extraPayment + +minPayment) * 100) / 100);
  }, [extraPayment, minPayment]);

  // METHODS

  const handleChangeCommitted = (event, value) => {
    onChangeCommittedValue(value);
  };

  const handleChange = (event, newValue) => {
    setExtraInput(newValue);
  };

  const handleExtraTextField = (event) => {
    const value = event.target.value;
    setExtraTextField(value);
    debounceExtra(+value);
  };

  const handleTotalPaymentChange = (event) => {
    const value = event.target.value;
    setTotalPayment(value);
    debounceTotal(+value);
  };

  // DOM

  const getSliderLabel = (value) => {
    return (
      <span>
        {/* <TextField
          label=""
          id="outlined-size-small"
          defaultValue={minPayment + value}
          type="number"
          variant="outlined"
          size="small"
        /> */}
        {/* <span>{`$${commas(minPayment + value)}`}</span>
        <br /> */}
        <span
          style={{
            display: "inline-block",
            color: green[500],
            fontSize: 13,
            lineHeight: 1.2,
          }}
        >
          {`$${commas(value)}`} <br /> extra
        </span>
      </span>
    );
  };

  const marks = [
    {
      value: 0,
      label: "$0",
    },
    {
      value: max,
      label: `$${commas(max)}`,
    },
  ];

  return (
    <div className={x.container}>
      <div className={x.slider}>
        <ThermoSlider
          orientation="vertical"
          // getAriaValueText={valuetext}
          min={0}
          max={max}
          step={0.01}
          defaultValue={0}
          aria-labelledby="vertical-slider"
          valueLabelDisplay="on"
          valueLabelFormat={getSliderLabel}
          marks={marks}
          value={extraInput}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
        />
      </div>
      <form style={{ maxWidth: 200 }}>
        <TextField
          style={{ margin: "8px 0" }}
          type="number"
          label="Extra Pmt / month"
          variant="outlined"
          size="small"
          fullWidth
          value={extraTextField}
          onChange={handleExtraTextField}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          inputProps={{ min: 0 }}
        />
        <div style={{ margin: 10, textAlign: "center" }}>Or</div>
        <TextField
          style={{ margin: "8px 0" }}
          type="number"
          label="Total Pmt / month"
          variant="outlined"
          size="small"
          fullWidth
          value={totalPayment}
          onChange={handleTotalPaymentChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          inputProps={{ min: minPayment }}
        />
        <Typography
          variant="caption"
          style={{ display: "block", margin: "30px 15px" }}
        >
          Use the slider to see how much interest you can save by paying a
          little extra each month.
        </Typography>
      </form>
    </div>
  );
};

PaymentSlider.propTypes = propTypes;

export default PaymentSlider;
