import React from "react";
import { bool, array, number, string, object, oneOfType } from "prop-types";
import green from "@material-ui/core/colors/green";
import { DateTime } from "luxon";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from "recharts";

import { commas, sum } from "../../utils/functions";
import { getPurples } from "../../utils/colors";
const COLORS = getPurples();

const ANIMATION_DURATION = 500;

const getXFormat = (value = 0) => {
  const now = DateTime.now();

  if (value === Infinity || value === -Infinity) {
    return now.toFormat("MMM '’'yy");
  }

  const period = now.plus({ months: value });
  return period.toFormat("MMM '’'yy");
};

const ReferenceLabel = ({
  offset,
  fill,
  value,
  textAnchor,
  fontSize,
  viewBox,
  dy,
  dx,
  background,
}) => {
  const x = viewBox.x;
  const y = viewBox.y + offset;
  return (
    <>
      {background && (
        <rect
          x={x - 30}
          y="2"
          rx="2"
          width="60"
          height="50"
          fill={background}
        />
      )}
      <text
        x={x}
        y={y}
        dy={dy}
        dx={dx}
        offset={offset}
        fill={fill}
        fontSize={fontSize || 16}
        fontWeight="700"
        textAnchor={textAnchor || "middle"}
      >
        {value}
      </text>
    </>
  );
};
ReferenceLabel.propTypes = {
  offset: number,
  fill: string,
  value: string,
  textAnchor: string,
  fontSize: number,
  viewBox: object,
  dy: number,
  dx: number,
  background: string,
};
const getTitle = (debtId, debts) => {
  const titles = {
    totalMinFv: "Min. Pmt. Balance",
    ...debts.reduce((r, d) => {
      r[d.id] = d.title;
      return r;
    }, {}),
  };
  return titles[debtId];
};
const CustomTooltip = ({
  debts,
  active,
  payload: chartDebts,
  label: period,
}) => {
  if (active && chartDebts && chartDebts.length) {
    const reversedChartDebts = [...chartDebts].reverse();
    return (
      <div
        style={{
          background: "white",
          padding: 8,
          boxShadow:
            "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
          fontSize: 14,
        }}
      >
        <p style={{ fontWeight: 600 }}>{getXFormat(period)}</p>
        {reversedChartDebts.map((d) => (
          <p
            key={d.dataKey}
            style={
              d.dataKey === "totalMinFv"
                ? {
                    borderTop: "1px solid #ccc",
                    paddingTop: 10,
                  }
                : {}
            }
          >{`${getTitle(d.dataKey, debts)}: $${commas(d.value)}`}</p>
        ))}
        {/* <p className="desc">Anything you want can be displayed here.</p> */}
      </div>
    );
  }

  return null;
};
CustomTooltip.propTypes = {
  debts: array,
  active: bool,
  payload: array,
  label: oneOfType([number, string]),
};

const propTypes = {
  debts: array,
  periods: array,
  minimumPeriods: array,
};

const Chart = ({ debts = [], periods, minimumPeriods }) => {
  const data =
    minimumPeriods.length > 0
      ? [
          {
            name: 0,
            ...minimumPeriods[0].reduce((result, debt, index) => {
              result[debt.id] = debt.pv || 0;
              return result;
            }, {}),
            totalMinFv: sum(minimumPeriods[0], "pv"),
          },
          ...minimumPeriods.map((minPeriodDebts, ind) => {
            const values = minPeriodDebts.reduce((result, debt, index) => {
              result[debt.id] = periods[ind]?.[index]?.fv || 0;
              return result;
            }, {});
            return {
              name: ind + 1,
              ...values,
              totalMinFv: sum(minPeriodDebts, "fv"),
            };
          }),
        ]
      : [];
  const startingBalance = sum(periods?.[0] || [], "fv");
  const getYFormat = (value) => {
    if (value === 0) return "";

    if (startingBalance > 5000) {
      return `$${commas(value / 1000)}k`;
    } else {
      return `$${commas(value)}`;
    }
  };
  const reversedDebts = [...debts].reverse();
  const plannedNumPeriods = periods.length;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 60,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey="name"
          type="number"
          tickCount="8"
          tickFormatter={getXFormat}
        />
        <YAxis tickFormatter={getYFormat} />
        <Tooltip content={<CustomTooltip debts={debts} />} />
        <Area
          type="monotone"
          dataKey="totalMinFv"
          stackId="2"
          stroke="#eeeeee"
          fill="#eeeeee"
          animationDuration={ANIMATION_DURATION}
        />
        {reversedDebts.map((debt, index) => (
          <Area
            key={debt.id}
            type="monotone"
            dataKey={debt.id}
            stackId="1"
            stroke={COLORS[index]}
            fill={COLORS[index]}
            animationDuration={ANIMATION_DURATION}
          />
        ))}
        {/* MIN PAYMENT REFERENCE LINE */}
        {data.length > 0 && (
          <ReferenceLine
            x={minimumPeriods.length}
            stroke="rgba(0,0,0,0.34)"
            strokeWidth={2}
          >
            <Label
              // value={getXFormat(minimumPeriods.length)}
              offset={30}
              position="insideTop"
              content={
                <ReferenceLabel
                  value={getXFormat(minimumPeriods.length)}
                  background="white"
                  fill="rgba(0,0,0,0.54)"
                  fontSize={20}
                />
              }
            />
          </ReferenceLine>
        )}
        {/* PLANNED PAYMENT REFERENCE LINE */}
        {data.length > 0 && minimumPeriods.length !== periods.length && (
          <ReferenceLine
            x={plannedNumPeriods}
            stroke={green[500]}
            strokeWidth={2}
            isFront
          >
            <Label
              // value={getXFormat(data[data.length - 1].name)}
              offset={30}
              position="insideTop"
              isFront
              content={
                <ReferenceLabel
                  value={getXFormat(plannedNumPeriods)}
                  background="white"
                  fill={green[500]}
                  fontSize={20}
                />
              }
            />
          </ReferenceLine>
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

Chart.propTypes = propTypes;

export default Chart;
