import React from "react";
import { number, string } from "prop-types";
import { green, deepPurple } from "@material-ui/core/colors";
import { ResponsiveContainer, BarChart, Bar, LabelList } from "recharts";
import { commas } from "../../utils/functions";

import x from "./InterestChart.module.scss";
import { useMediaQuery } from "@material-ui/core";

const ANIMATION_DURATION = 500;

const propTypes = {
  type: string,
  monthDiff: number,
  extra: number,
  principal: number,
  interest: number,
  minPaymentInterest: number,
};

const InterestChart = ({
  type = "minimum",
  monthDiff,
  extra,
  principal,
  interest,
  minPaymentInterest,
}) => {
  // CONTEXT

  const desktop = useMediaQuery("(min-width:1200px)");

  // DOM

  const interestSaved = Math.round((minPaymentInterest - interest) * 100) / 100;
  const data = [
    {
      name: type,
      principal,
      interest,
      interestSaved,
      minPaymentInterest,
      zero: 0,
      default: 1,
    },
  ];

  const format = (value) => `$${commas(value)}`;

  const show = data[0].interestSaved > 0;

  const monthDiffLabel =
    monthDiff < 24
      ? `${monthDiff} months`
      : `${Math.round(monthDiff / 12)}yrs ${monthDiff % 12}mths`;

  return (
    <div style={{ display: "flex", flexDirection: desktop ? "row" : "column" }}>
      <div
        style={{
          height: 200,
          width: 250,
          ...(desktop ? { marginRight: 20 } : { margin: "0 auto" }),
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart className={x.chart} data={data}>
            {principal === 0 ? (
              <Bar
                dataKey="default"
                stackId="1"
                fill="#e5e5e5"
                barSize={50}
                animationDuration={ANIMATION_DURATION}
              />
            ) : (
              [
                <Bar
                  key="interest"
                  dataKey="interest"
                  stackId="1"
                  fill={deepPurple[300]}
                  barSize={50}
                  animationDuration={ANIMATION_DURATION}
                />,
                <Bar
                  key="zero-interest"
                  dataKey="zero"
                  stackId="1"
                  fill="#e5e5e5"
                  barSize={50}
                  animationDuration={ANIMATION_DURATION}
                >
                  {show && (
                    <LabelList
                      position="left"
                      dataKey="interest"
                      formatter={format}
                    />
                  )}
                  {show && (
                    <LabelList
                      style={{ fontSize: 13 }}
                      position="right"
                      dataKey="interest"
                      formatter={() => `Interest w/ extra payments`}
                    />
                  )}
                </Bar>,
                <Bar
                  key="interestSaved"
                  dataKey="interestSaved"
                  stackId="1"
                  fill="#e5e5e5"
                  barSize={50}
                  animationDuration={ANIMATION_DURATION}
                />,
                <Bar
                  key="zero-saved"
                  dataKey="zero"
                  stackId="1"
                  fill="#e5e5e5"
                  barSize={50}
                  animationBegin={0}
                  animationDuration={ANIMATION_DURATION}
                >
                  <LabelList
                    position="left"
                    dataKey="minPaymentInterest"
                    formatter={format}
                  />
                  <LabelList
                    style={{ fontSize: 13 }}
                    position="right"
                    dataKey="minPaymentInterest"
                    formatter={() => `Interest w/ min payments`}
                  />
                </Bar>,
              ]
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div
        style={{
          fontSize: 18,
          width: desktop ? 200 : "100%",
          textAlign: desktop ? "center" : "left",
          lineHeight: 1.3,
          order: desktop ? 0 : -1,
          marginBottom: 40,
        }}
      >
        {extra > 0 ? (
          <div>
            <span style={{ fontSize: 20 }}>
              You save{" "}
              <span
                style={{
                  display: desktop ? "block" : "inline",
                  color: green[500],
                  fontSize: 24,
                  fontWeight: 700,
                }}
              >
                ${commas(interestSaved)}
              </span>{" "}
              paying an extra{" "}
              <span style={{ whiteSpace: "nowrap" }}>
                <span style={{ color: green[500], fontWeight: 700 }}>
                  ${commas(extra)}
                </span>{" "}
                /mo
              </span>
            </span>
            <br />
            <br />
            <span>
              (And you pay off your debt <b>{monthDiffLabel}</b> sooner)
            </span>
          </div>
        ) : principal > 0 ? (
          <div>
            <span style={{ fontSize: 20 }}>
              {`You'll pay`}{" "}
              <span
                style={{
                  display: desktop ? "block" : "inline",
                  fontSize: 24,
                  fontWeight: 700,
                }}
              >
                ${commas(minPaymentInterest)}
              </span>{" "}
              in interest when making the minimum payment each month.
            </span>
            <br />
            <br />
            <span>
              (See how much you can save by paying a little extra each month.)
            </span>
          </div>
        ) : (
          <div>
            <span style={{ fontSize: 20 }}>No debts entered yet</span>
          </div>
        )}
      </div>
      {/* <ResponsiveContainer width="100%" height="40%">
        <PieChart width={400} height={400}>
          <Pie
            data={dataPie}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            // label
            outerRadius={60}
            fill="#8884d8"
            dataKey="value"
            startAngle={90}
            endAngle={90 + 360}
          >
            {dataPie.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer> */}
    </div>
  );
};

InterestChart.propTypes = propTypes;

export default InterestChart;
