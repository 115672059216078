import { auth } from "../config/firebase";
import { useMutation } from "./useFirestore";

function useCreateUser() {
  const [createUsers] = useMutation("create", "users");
  const [updateUsers] = useMutation("update", "users");
  const [createDebts] = useMutation("create");
  const [createUserScenarios] = useMutation("create");

  const createUser = async (
    email,
    password,
    debts = [],
    extraPayment = 0,
    order = "avalanche"
  ) => {
    const { user } = await auth.createUserWithEmailAndPassword(email, password);

    await createUsers([{ _id: user.uid, email: user.email }]);
    let debtIds = [];

    if (debts.length > 0) {
      debtIds = await createDebts(debts, `users/${user?.uid || ""}/debts`);
    }

    const scenarios = [{ extraPayment, debts: debtIds, order }];
    const scenarioIds = await createUserScenarios(
      scenarios,
      `users/${user?.uid || ""}/scenarios`
    );
    await updateUsers([{ _id: user.uid, activeScenario: scenarioIds[0] }]);
  };

  return createUser;
}

export { useCreateUser };
