import React from "react";
import { node } from "prop-types";

import { auth } from "../config/firebase";
import { useSubscription } from "./useFirestore";

const UserStateContext = React.createContext();

UserProvider.propTypes = { children: node };

function UserProvider({ children }) {
  // STATE
  const [authUser, setAuthUser] = React.useState({
    user: auth.currentUser || null,
    loading: true,
    called: false,
  });

  // EFFECTS
  React.useEffect(() => {
    const unlistenAuth = auth.onAuthStateChanged((user) => {
      setAuthUser({ user: user || null, loading: false, called: true });
    });
    return () => {
      unlistenAuth();
    };
  }, []);

  const [userData, loadingUserData, error] = useSubscription(
    authUser.user ? `users/${authUser.user.uid}` : "",
    undefined,
    !authUser.user
  );

  const userObject = {
    user: userData || null,
    loading:
      authUser.loading || loadingUserData || (!!authUser.user && !userData),
    called: authUser.called && (!authUser.user || !!userData),
    error,
  };

  return (
    <UserStateContext.Provider value={userObject}>
      {children}
    </UserStateContext.Provider>
  );
}

/**
 * Returns an object containing the 'user' from firestore, loading state, and whether it's been called
 * @returns {Object} Containing { user, loading , called}
 */
function useUser() {
  const userContext = React.useContext(UserStateContext);
  if (userContext === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return userContext;
}

async function signInWith(method, ...args) {
  if (method !== "token" && method !== "email") {
    throw new Error('signInWith must designate a method of "token" or "email"');
  }
  if (method === "token") {
    return auth.signInWithCustomToken(...args);
  }
  if (method === "email") {
    return auth.signInWithEmailAndPassword(...args);
  }
}

async function signOut() {
  return auth.signOut();
}

export { UserProvider, useUser, signInWith, signOut };
