import React from "react";
import { bool, func } from "prop-types";

// import "./BottomDrawer.scss";
import {
  AppBar,
  Button,
  // Drawer,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "8px 20px 16px",
    color: "rgba(0,0,0,0.87)",
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "8px 20px",
  },
}));

const propTypes = {
  open: bool,
  onCreateAccountRequest: func,
};

const BottomDrawer = ({ open, onCreateAccountRequest }) => {
  const classes = useStyles();
  if (!open) return null;

  return (
    <AppBar
      position="fixed"
      color="inherit"
      component="div"
      className={classes.appBar}
    >
      <Typography variant="h6">Save your info</Typography>
      <Button
        variant="outlined"
        color="inherit"
        onClick={onCreateAccountRequest}
      >
        Create Account
      </Button>
    </AppBar>
  );
  // return (
  //   <Drawer
  //     className={classes.drawer}
  //     open={open}
  //     classes={{
  //       paper: classes.drawerPaper,
  //     }}
  //     anchor="bottom"
  //   >
  //     <Typography variant="h6">Save your info</Typography>
  //     <Button variant="outlined">Create Account</Button>
  //   </Drawer>
  // );
};

BottomDrawer.propTypes = propTypes;

export default BottomDrawer;
