import { useEffect, useState } from "react";
import { sum } from "../utils/functions";
import { fv, getPmt } from "../utils/financial";

function usePayments(debts, payment, type) {
  const [periods, setPeriods] = useState([]);
  const debtsString = JSON.stringify(debts);

  useEffect(() => {
    const debtsArr = JSON.parse(debtsString);
    setPeriods(getPeriods(debtsArr, payment));
  }, [debtsString, payment]);

  const principal = debts.length > 0 ? sum(debts, "pv") : 0;
  const totalPayments =
    periods.length > 0 ? sum(periods.map((per) => sum(per, getPmt))) : 0;
  const totalInterest = totalPayments - principal;

  return { periods, principal, totalPayments, totalInterest };
}

export { usePayments };

function getPeriods(debts, payment) {
  // const periodsFVs = [];
  const periods = [];
  const totalFV = debts.reduce((a, v) => a + v.pv, 0);
  let remainingFV = totalFV;
  // starting debt amounts
  let prevPerDebtsFVs = debts.map((debt) => debt.pv);

  while (remainingFV > 0) {
    const debtsPVs = prevPerDebtsFVs;
    const debtsFVsBeforePMT = debts.map((debt, index) =>
      fv(debt.rate / 12, 1, 0, -debtsPVs[index])
    );

    const debtsPMTsBeforePMT = debts.map((debt, index) =>
      Math.min(getPmt(debt), debtsFVsBeforePMT[index])
    );
    let remainingPMT =
      Math.round((payment - sum(debtsPMTsBeforePMT)) * 100) / 100;
    // const periodDebts = [];

    const periodDebts = debts.map((debt, index) => {
      const debtPMT = Math.min(
        Math.round((debtsPMTsBeforePMT[index] + remainingPMT) * 100) / 100,
        debtsFVsBeforePMT[index]
      );
      const minPmt = getPmt(debt);
      remainingPMT +=
        debtPMT > minPmt ? Math.round((minPmt - debtPMT) * 100) / 100 : 0;
      const debtPV = debtsPVs[index];
      const debtFV = fv(debt.rate / 12, 1, debtPMT, -debtPV);
      return { id: debt.id, pmt: debtPMT, fv: debtFV, pv: debtPV };
    });
    // periodsFVs.push(debtsFVs);
    periods.push(periodDebts);
    prevPerDebtsFVs = periodDebts.map((d) => d.fv);
    // prevPerDebtsFVs = debtsFVs; // ["34443.48", "89429.66", "11927.79"]
    // run out...
    const newRemaining = sum(prevPerDebtsFVs);

    if (newRemaining > remainingFV) {
      // bail
      remainingFV = 0;
    } else {
      remainingFV = sum(prevPerDebtsFVs);
    }
  }
  return periods;
}
