import React, { Fragment } from "react";
import { array, string, any, number, bool, func } from "prop-types";
import {
  Divider,
  Drawer,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useUser } from "../../hooks/useUser";
import AccountAccessForm from "../AccountAccessForm/AccountAccessForm";
import x from "./SideDrawer.module.scss";
import { ChevronLeft } from "@material-ui/icons";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    padding: "0 20px",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const propTypes = {
  open: bool,
  swipable: bool,
  children: any,
  debts: array,
  extraPayment: number,
  order: string,
  onClose: func,
};

const SideDrawer = ({ children, debts = [], extraPayment, order, onClose }) => {
  const classes = useStyles();
  const { user } = useUser();

  return (
    <>
      <div>
        <div className={classes.toolbar}>
          <Typography variant="h6">Debt Timeline</Typography>
          <IconButton onClick={onClose}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <div className={x.content}>{children}</div>
      </div>
      <div>
        <Divider />
        <div style={{ padding: "20px 20px 30px" }}>
          {!user ? (
            <>
              <div style={{ margin: "16px 0" }}>
                <p>
                  Sign up to save your info.
                  <br />
                  <span
                    style={{
                      color: "rgba(0, 0, 0, 0.54)",
                      display: "block",
                      marginTop: 4,
                      fontSize: 14,
                    }}
                  >{`(We'll never email you)`}</span>
                </p>
              </div>
              <AccountAccessForm
                debts={debts}
                extraPayment={extraPayment}
                order={order}
              />
            </>
          ) : (
            <div>
              <span>
                Signed in as:
                <br />
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    display: "block",
                    marginTop: 4,
                    fontSize: 16,
                  }}
                >
                  {user?.email || ""}
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

SideDrawer.propTypes = propTypes;

const DrawerWrapper = ({ open, children, swipable, ...props }) => {
  const classes = useStyles();
  if (swipable) {
    return (
      <SwipeableDrawer
        className={classes.drawer}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        onClose={props.onClose}
      >
        <SideDrawer {...props}>{children}</SideDrawer>
      </SwipeableDrawer>
    );
  } else {
    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <SideDrawer {...props}>{children}</SideDrawer>
      </Drawer>
    );
  }
};
DrawerWrapper.propTypes = propTypes;

export default DrawerWrapper;
