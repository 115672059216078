// import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { orange, deepPurple } from "@material-ui/core/colors";
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: orange[500],
    },
    primary: {
      main: deepPurple[500],
    },
  },
});

export { theme };
